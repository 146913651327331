// extracted by mini-css-extract-plugin
export var contact = "contact-module--contact--21FJP";
export var emailLink = "contact-module--emailLink--2Z0kH";
export var socials = "contact-module--socials--ZThhr";
export var userEntries = "contact-module--userEntries--2dLJE";
export var editButton = "contact-module--editButton--1FD86";
export var fieldValue = "contact-module--fieldValue--3c0mq";
export var profileLinks = "contact-module--profileLinks--2YcdZ";
export var profile = "contact-module--profile--2HsYN";
export var profileLink = "contact-module--profileLink--3fR57";
export var networkName = "contact-module--networkName--3QJt8";
export var github = "contact-module--github--1vFah";
export var linkedIn = "contact-module--linkedIn--20jgv";
export var twitter = "contact-module--twitter--yZKdo";
export var editIcon = "contact-module--editIcon--2_NSY";
export var messageGroup = "contact-module--messageGroup--1vlyc";
export var toastNotif = "contact-module--toastNotif--1R_Yh";
export var toastNotifInner = "contact-module--toastNotifInner--uV4mQ";
export var notifBtn = "contact-module--notifBtn--1gtzu";
export var contactInner = "contact-module--contactInner--2mfvC";
export var formGroup = "contact-module--formGroup--qhH8y";
export var errorHighlight = "contact-module--errorHighlight--3SxoO";